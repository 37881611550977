import React, {Component} from "react";
import 'react-awesome-slider/dist/styles.css';
import EmbelaCarousel from "../components/EmbelaCarousel";
import 'pure-react-carousel/dist/react-carousel.es.css';

import "./Home.css"
import Footer from "../components/Footer";

export default class Home extends Component {


    render() {

        const OPTIONS = {loop: true}
        const SLIDE_COUNT = 6
        const SLIDES = Array.from(Array(SLIDE_COUNT).keys())
        return (
            <div className="home">
                <div className="home-body">
                    <div className="welcome-message">
                        <h2>Welcome to Shalom Home Care</h2>
                        <p>
                            We're so pleased that you've discovered our adult family home. Our goal is to provide a safe, comfortable, and nurturing environment for each of our residents.
                            <br/>
                            Our home is located in one of quiet neighborhoods of Lake Stevens WA , optimal for providing peace and relaxation for our residents. The home is designed and maintained at high standards to accommodate and provide comfort and safety for your loved one.
                        </p>
                    </div>
                    <div className="carousel-container">
                        <EmbelaCarousel slides={SLIDES} options={OPTIONS}/>
                    </div>

                    <div className='celphone-carousel-image'>
                        <img src="/assets/images/ramp2.png" alt=""/>
                    </div>
                </div>

                <div className="home-footer">
                    <Footer />
                </div>
            </div>
        );
    }
}
