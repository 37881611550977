import image1 from "../images/ramp2_cropped.png"
import image2 from "../images/kitchen_cropped.png"
import image3 from "../images/ramp_cropped.png"
import image4 from "../images/bed_cropped.png"
import image5 from "../images/salon_cropped.png"
import image6 from "../images/salon2_cropped.png"


export const images = [image1, image2, image3, image4, image5, image6]

const imageByIndex = (index) => images[index % images.length]

export default imageByIndex