import React, {Component} from "react";
import Footer from "../components/Footer";
import "./ContactUs.css"
import {MdMarkEmailRead, MdOutlineLocationOn} from "react-icons/md";
import {LiaPhoneAltSolid} from "react-icons/lia";

export default class ContactUs extends Component {
    render() {
        return (
            <div className="contact-page">
                <div className="contact-body">
                    <div className="contact-text">
                        <div className="text-top">
                            <h3> CONTACT US </h3>
                            <p>
                                Do you want more information about our facility? if you’ve any question, or simply want to know more about what we do, then please reach out to us:
                            </p>
                        </div>
                        <div className="text-bottom">
                            <a  href="tel:206-883-3391" > <LiaPhoneAltSolid/> 206-883-3391</a>
                            <p> <MdMarkEmailRead/> Email: shalomhomecares@gmail.com</p>
                            <p>
                                <MdOutlineLocationOn/>
                                <br/> 219 81st Ave NE <br/> Lake Stevens, WA 98253
                            </p>
                        </div>

                    </div>
                    <div className="contact-cal">
                        <h3>Book a Visit</h3>
                        <div style={{height: "600px"}}>
                            <iframe
                                src="https://calendly.com/shalomhomecares/30min"
                                width="100%"
                                height="100%"
                                title="calendar"
                            ></iframe>
                        </div>
                    </div>
                </div>

                <div className="gmap_canvas">
                    <iframe id="gmap_canvas"
                            src="https://maps.google.com/maps?q=219%2081st%20AVE%20NE%20lakeste&t=&z=13&ie=UTF8&iwloc=&output=embed"
                            title="map"></iframe>

                </div>

                <div className="contact-footer">
                    <Footer />
                </div>
            </div>
        );
    }
}
