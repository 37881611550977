import React, { Component } from "react";
export default class BookAVisit extends Component {
    render() {
        return (
            <div>
                <div style={{height: "800px"}}>
                    <iframe
                        src="https://calendly.com/temesgen-hilla/30min"
                        width="100%"
                        height="100%"
                        title="calendar"
                    ></iframe>
                </div>
            </div>
        );
    }
}