import React, {useState} from "react";

import "./Navbar.css";
import {Link, NavLink} from "react-router-dom";
import logo from "../images/logo.png"
import {TiThMenuOutline} from "react-icons/ti";

export const Navbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <nav>
            <div className="logo">
                <a href="/">
                    <img src={logo} alt=""/>
                </a>

                <Link to="/" className="title">
                    Shalom Home Care
                </Link>
            </div>
            <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
                <TiThMenuOutline className="icon"/>
            </div>
            <ul className={menuOpen ? "open" : ""} onClick={() => setMenuOpen(false)}>
                <li>
                    <NavLink to="/home">  Home  </NavLink>
                </li>
                <li>
                    <NavLink to="/about">About Us</NavLink>
                </li>
                <li>
                    <NavLink to="/tour"> Gallery </NavLink>
                </li>
                <li>
                    <NavLink to="/contact"> Contact </NavLink>
                </li>

            </ul>
        </nav>
    );
};