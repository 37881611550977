import './App.css';
import React from 'react'
import PageRouter from "./components/PageRouter"

function App() {
    return (
        <div className="app" >
            <PageRouter/>
        </div>
    );
}

export default App;
