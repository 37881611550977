import React, { Component } from "react";
import Footer from "../components/Footer";
import "./AboutUs.css";

export default class AboutUs extends Component {
  render() {
    return (
        <div className="about-page">
            <div className="about-top">
                <div className="about-text">
                    <h2> ABOUT US</h2>
                    <p>
                        We're so pleased that you've discovered our adult family home. Our goal is to provide a safe, comfortable, and nurturing environment for each of our residents.
                        <br/><br/>
                        At Shalom Home Care, you'll find a small, intimate setting that feels like home. Our compassionate caregivers work closely with each resident's loved ones to develop personalized care plans tailored to their unique needs and preferences.
                        <br/><br/>
                        We know that this can be a difficult and emotional time for families. That's why communication and trust are so important to us. You'll always know what's happening with your loved one's care and be involved in every step of the process.
                        <br/><br/>
                        Within our cozy, homelike residence, residents enjoy home cooked meals, social activities, transportation, wide yard for a walk and many more. We promote independence while providing supportive care and assistance with daily living activities. Our staff is available 24/7 to meet residents' needs as they arise.
                        <br/><br/>
                        We would love to invite you in for a tour and to answer any questions you may have. Please feel free to call or email us anytime. We are happy to help as you explore options for your loved one's care.
                    </p>
                </div>
                <div className="about-image">
                    <img src="/assets/images/ramp.png" alt=""/>
                    <img className='not-on-cellphone-image' src="/assets/images/ramp2.png" alt=""/>
                </div>

            </div>
            <div className="gmap_canvas">
                <iframe id="gmap_canvas"
                        src="https://maps.google.com/maps?q=219%2081st%20AVE%20NE%20lakeste&t=&z=13&ie=UTF8&iwloc=&output=embed"
                        title="map"></iframe>

            </div>
            <div className="about-footer">
                <Footer/>
            </div>
        </div>
    );
  }
}
