// Footer.js

import React from 'react';
import { MdOutlineLocationOn, MdMarkEmailRead } from 'react-icons/md';
import { LiaPhoneAltSolid } from 'react-icons/lia';
import { RiHomeOfficeLine } from "react-icons/ri";

import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                <div className="row">
                    <div className="col">
                        <div className="address">
                            <MdOutlineLocationOn />
                            <div onClick={() => {
                                window.open("https://www.google.com/maps/place/219+81st+Ave+NE,+Lake+Stevens,+WA+98258/@47.9988887,-122.1213551,16z/data=!4m9!1m2!2m1!1s219+81st+Ave+NE+Lake+Stevens+WA+98253!3m5!1s0x549aab6e27578a59:0xb65fd0c2054c04ab!8m2!3d47.9988887!4d-122.1213551!16s%2Fg%2F11crwnl93m?source=lnms")
                            }}>
                                <p>
                                    219 81st Ave NE <br/>
                                    Lake Stevens WA 98253
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col">
                        <div className="phone">
                            <a href="tel:2068833391">
                                <LiaPhoneAltSolid /> (206)-883-3391
                            </a>
                        </div>
                    </div>

                    <div className="col">
                        <div className="email">
                            <MdMarkEmailRead />
                            <p>shalomhomecares@gmail.com</p>
                        </div>
                    </div>

                    <div className="col">
                        <div className="book">
                            <a href="/contact">
                                <RiHomeOfficeLine/>
                                <br/>
                                Book A VISIT
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;