import React, { useState } from 'react';
import { Media } from '../images'
import './ResponsiveGallery.css'

const ResponsiveGallery = () => {
    const [cur, setCur] = useState(null);
    let popup;
    if(cur !== null) {
        if (cur.type === 'video') {
            popup = <video src={cur.url} muted autoPlay controls/>
        } else {
            popup = <img src={cur.url} alt=""/>
        }
    }

    return (
        <div className='container'>

            <div className="media-container">
                {
                    Media.map((file, index) => (
                        <div className="media" key={index} onClick={() => setCur(file)}>
                            {
                                file.type === 'image'
                                ? <img src={file.url} alt="" />
                                : <video src={`$file.url}#t=0.001`} muted preload='metadata'/>
                            }
                        </div>
                    ))
                }
            </div>

            <div className='popup-media' style={{ display: cur ? 'block' : 'none'}}>
                <span  onClick={() => setCur(null)}>&times;</span>
                { popup }
            </div>
        </div>
    );
};

export default ResponsiveGallery;