import React from 'react';
import ResponsiveGallery from "../components/ResponsiveGallery";
import './TourFacility.css'
import Footer from "../components/Footer";

const TourFacility = () => {

    return (
        <div className='body'>
            <div>
                <ResponsiveGallery />
            </div>
            <div>
                <Footer />
            </div>

        </div>
    );
};

export default TourFacility;