export const Media = [
    {type: 'image', url: require('./shower.png')},
    {type: 'image', url: require('./ramp.png')},
    {type: 'image', url: require('./dining.png')},
    {type: 'image', url: require('./front.png')},
    {type: 'image', url: require('./ramp2.png')},
    {type: 'image', url: require('./salon.png')},
    {type: 'image', url: require('./salon2.png')},
    {type: 'image', url: require('./top-pic.png')},
    {type: 'image', url: require('./shower.png')}
]