import React from 'react'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import TourFacility from "../pages/TourFacility";
import ContactUs from "../pages/ContactUs";
import {Navbar} from "./Navbar";
import BookAVisit from "../pages/BookAVisit";


function PageRouter() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path='/' element={<Home/>} />
                <Route path="/home" element={<Home/>} />
                <Route path="/about" element={<AboutUs/>} />
                <Route path="/tour" element={<TourFacility/>} />
                <Route path="/contact" element={<ContactUs/>} />
                <Route path="/book" element={<BookAVisit/>} />
            </Routes>
        </Router>
    );
}

export default PageRouter;