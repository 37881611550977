import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import imageByIndex from './imageByIndex'
import "./EmbelaCarousel.css"

const EmblaCarousel = (props) => {
    const { slides, options } = props
    const [emblaRef] = useEmblaCarousel({loop: true}, [Autoplay()])

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((index) => (
                        <div className="embla__slide" key={index}>
                            <img
                                className="embla__slide__img"
                                src={imageByIndex(index)}
                                alt=""
                            />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default EmblaCarousel